import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BASE_URL } from "../../global";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { Grid, TextField, Typography, Autocomplete } from "@mui/material";
import { useParams } from "react-router-dom";
import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";
import { viewsdispatchListGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No." },
  { id: "companyName", label: "Client ", minWidth: 40 },
  { id: "address", label: "Address ", minWidth: 40 },
  {
    id: "dispatchMedium",
    label: "Dispatch Medium   ",
    align: "left",
    minWidth: 40,
  },

  {
    id: "dispatchDate",
    label: "Dispatch date ",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(id, companyName, address, dispatchMedium, dispatchDate, deliveryChallanNo) {
  return {
    id,
    companyName,
    address,
    dispatchMedium,
    dispatchDate,
    deliveryChallanNo
  };
}

export default function DispatchList(props) {
  const viewsdispatchListGuides = viewsdispatchListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dispatchList, setDispatchList] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [dispatchMedium, setDispatchMedium] = React.useState("");
  const [challanNo, setChallanNo] = React.useState("");
  const [dispatchDate, setDispatchDate] = React.useState("");
  const [instruments, setInstruments] = React.useState([]);
  var [instrumentArray, setInstrumentArray] = React.useState([]);
  var [instrumentIds, setInstrumentIds] = React.useState("");
  const [rowDaata, setRowData] = React.useState([]);
  const [flatArray, setFlatArray] = React.useState([]);
  const params = useParams();

  const getSrfsListtt = (event) => {
    let url = BASE_URL;
    let payload = {
      query: `SELECT clients.companyName AS client_companyName, srfs.id AS srf_id, srfInstruments.requestedDucName, srfInstruments.instrumentId AS srfInst_instrumentId,srfInstruments.serialNumber AS srfInst_SerialNumber,srfInstruments.DUCID AS srfInst_DUCID, srfInstruments.id AS srfInst_id, a.address AS client_address, instruments.instrumentName FROM srfs LEFT JOIN clients ON srfs.clientId = clients.id LEFT JOIN srfInstruments ON srfs.id = srfInstruments.srfsId LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(srfs.address, ',', 1), ',', -1) LEFT JOIN instruments ON srfInstruments.instrumentId = instruments.id WHERE srfs.id = ${params.id} AND srfs.clientId = ${params.clientId}`,
    };

    axiosWithToken
      .post(url + `dynamic`, payload)
      .then((res) => {
        setCompanyName(res.data[0].client_companyName);
        setAddress(res.data[0].client_address);
        setInstruments(res.data);
      })
      .catch((error) => console.log(error));
  };

  const getDispatchList = (event) => {
    
    let query = {
      query : `SELECT d.*, a.address AS dispatch_address FROM dispatch AS d LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(d.address, ',', 1), ',', -1) WHERE d.srfsId = ${params.id} AND d.clientId = ${params.clientId} AND d.status = 1 `
    }
    axiosWithToken
    .post(BASE_URL + `dynamic`, query)
      .then((res) => {
        setDispatchList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDispatchList();
    getSrfsListtt();
  }, []);

  const onSubmit = (id) => {
    var jsonData = {
      companyName: companyName,
      address: address,
      instrumentIds: instrumentIds,
      dispatchMedium: dispatchMedium,
      clientId: params.clientId,
      srfsId: params.id,
      dispatchDate: dispatchDate
        ? moment(dispatchDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      deliveryChallanNo:  challanNo,
    };

    let url = BASE_URL;

    var refresh = () => {
      window.location.reload(false);
    };
    axiosWithToken
      .post(url + `dispatch`, jsonData)
      .then((res) => {
        toast("Dispacth submitted!");
        setTimeout(refresh, 1000);
        updateSRFAnalytics(instrumentIds.split(","), 90);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `dispatch/${id}`, jsonData)
      .then((res) => {
        toast("Deleted dispatch!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const initializeInstruments = () => {
    const instrumentArray = [];
    for (let i = 0; i < instruments.length; i++) {
      let instrumentname = instruments[i].requestedDucName ? instruments[i].requestedDucName : instruments[i].instrumentName;
      instrumentArray.push({
        label: instruments[i].srfInst_id + ", " + instrumentname + ", " + instruments[i].srfInst_DUCID,
      });
    }
    setInstrumentArray(instrumentArray);
  };

  useEffect(() => {
    initializeInstruments();
  }, [instruments]);

  const generateRowData = (dispatchList) => {
    const newRowData = [];
    for (let i = 0; i < dispatchList.length; i++) {
      newRowData.push(
        createData(
          dispatchList[i].id,
          dispatchList[i].companyName,
          dispatchList[i].address,
          dispatchList[i].dispatchMedium,
          moment(dispatchList[i].dispatchDate).format("DD-MM-YYYY"),
          dispatchList[i].deliveryChallanNo,
        )
      );
    }
    return newRowData;
  };

  const calculateFlatArray = (dispatchList) => {
    const uniqueInstruments = [
      ...new Set(dispatchList.flatMap((ele) => ele.instrumentIds.split(","))),
    ];
    setFlatArray(uniqueInstruments);
  };

  const filteredInstrumentArray = instrumentArray.filter((item) => {
    const itemValue = item.label.split(",")[0];
    return !flatArray.includes(itemValue);
  });

  useEffect(() => {
    const updatedRowData = generateRowData(dispatchList);
    setRowData(updatedRowData);
    calculateFlatArray(dispatchList);
  }, [dispatchList]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              viewsdispatchListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Dispatch
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Client"
                size="small"
                value={companyName}
                InputLabelProps={{ shrink: true }}
                disabled
                fullWidth
                variant="outlined"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Address"
                size="small"
                value={address}
                InputLabelProps={{ shrink: true }}
                disabled
                fullWidth
                variant="outlined"
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="srf-viewdispatchlist_instruments"
                options={filteredInstrumentArray}
                multiple
                renderInput={(params) => (
                  <TextField {...params} label="Instruments" />
                )}
                onChange={(event, newInputValue) => {
                  const ids = [];
                  newInputValue.map((data, i) => {
                    ids.push(newInputValue[i].label.split(",")[0]);
                  });
                  setInstrumentIds(ids.toString());
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="srf-viewdispatchlist_dispatchmedium"
                label="Dispatch Medium"
                size="small"
                fullWidth
                variant="outlined"
                onChange={(e) => setDispatchMedium(e.target.value)}
              />
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Dispatch Date"
                  value={dispatchDate ? dispatchDate : new Date()}
                  InputLabelProps={{ shrink: true }}
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setDispatchDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          <Grid item xs={3}>
              <TextField
                label="Challan No"
                size="small"
                fullWidth
                variant="outlined"
                onChange={(e) => setChallanNo(e.target.value)}
              />
            </Grid>
          </Grid>
          <hr />

          <Toolbar style={{ padding: "0px" }}>
            <Button
              id="srf-viewdispatchlist_dispatchadd"
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={() => {
                  if (!instrumentIds) {
                    toast.error('Please select a Instruments');
                    return;
                  }
                  if (!dispatchMedium) {
                    toast.error('Dispatch Medium is required');
                    return;
                  }                
                onSubmit();
              }}
            >
              Add Dispatch
            </Button>
          </Toolbar>
          <ToastContainer />
        </Paper>

        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="srf-viewdispatchlist_dispatchchallan"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowDaata
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="View Dispatch" placement="top-start">
                          <Button
                            id="srf-viewdispatchlist_dispatchchallandownload"
                            style={{
                              borderColor: "#dc3545",
                            }}
                            component={Link}
                            to={`/dispatch/viewDispatch/${params.id}/${row.id}/${instruments.length}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        {/* <Tooltip title="Edit Dispatch" placement="top-start">
                          <Button
                            id="srf-viewdispatchlist_dispatchchallanedit"
                            style={{
                              borderColor: "#dc3545",
                            }}
                            component={Link}
                            to={`/dispatch/editDispatch/${row.id}`}
                          >
                            <EditIcon />
                          </Button>
                        </Tooltip> */}
                        <Tooltip title="Delete Dispatch" placement="top-start">
                          <Button
                            id="srf-viewdispatchlist_dispatchchalland_delete"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete dispatch?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                            style={{
                              borderColor: "#dc3545",
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rowDaata.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </TableContainer>
    </>
  );
}
